import React, {
  Dispatch,
  memo,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import { TextField } from "@mui/material";
import { BackendItem, LendingItem } from "../types";
import { findLending } from "../utils/utils";
import FractionBar from "../FractionBar";

export type ItemTableProps = {
  mode: "manageItems" | "editLending";
  plannedSelections?: Map<number, number>;
  setPlannedSelections?: Dispatch<SetStateAction<Map<number, number>>>;
  lendSelections?: Map<number, number>;
  setLendSelections?: Dispatch<SetStateAction<Map<number, number>>>;
  lendingId?: number;
  data: any;
};

function getAvailableItemCount(
  data: any,
  count: number,
  countLend: number,
  itemId: number,
  lendingId: number | undefined
) {
  return (
    count -
    countLend +
    (findLending(data, String(lendingId))
      ? findLending(data, String(lendingId))!.items.find(
          (item: LendingItem) => item.itemId === itemId
        )?.count ?? 0
      : 0)
  );
}

function ItemTable(props: ItemTableProps) {
  const navigate = useNavigate();

  const data = props.data;
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      marking: false,
    });

  const isEditingLending =
    props.mode === "editLending" &&
    props.plannedSelections &&
    props.setPlannedSelections &&
    props.lendSelections &&
    props.setLendSelections;

  const renderSelectionCell =
    (
      map: Map<number, number>,
      mapSetter: Dispatch<SetStateAction<Map<number, number>>> | undefined,
      maxFunction: (
        data: any,
        count: number,
        countLend: number,
        itemId: number,
        lendingId: number | undefined
      ) => number
    ) =>
    (params: GridRenderCellParams<number>) =>
      (
        <TextField
          type="number"
          InputProps={{
            inputProps: {
              min: 0,
              max: maxFunction(
                data,
                params.row.count,
                params.row.countLend,
                params.row.idBackend,
                props.lendingId
              ),
            },
          }}
          value={map!.get(params.row.idBackend) ?? 0}
          onChange={(e) =>
            mapSetter &&
            mapSetter((prevState: Map<number, number>) => {
              let map = new Map(prevState);
              map.set(params.row.idBackend as number, Number(e.target.value));
              return map;
            })
          }
        />
      );

  const columns: GridColDef[] = useMemo(() => {
    const columns: GridColDef[] = [];

    if (isEditingLending)
      columns.push(
        {
          field: "countAvailable",
          headerName: "Verfügbar",
          flex: 1,
        },
        {
          field: "selection",
          headerName: "geplant",
          renderCell:
            props.plannedSelections && props.setPlannedSelections
              ? renderSelectionCell(
                  props.plannedSelections,
                  props.setPlannedSelections,
                  (data1, count, countLend, itemId, lendingId) => count
                )
              : () => <></>,
          flex: 1.3,
        },
        {
          field: "selectionLend",
          headerName: "ausgegeben",
          renderCell:
            props.lendSelections && props.setLendSelections
              ? renderSelectionCell(
                  props.lendSelections,
                  props.setLendSelections,
                  getAvailableItemCount
                )
              : () => <></>,
          flex: 1.3,
        }
      );

    columns.push(
      { field: "summary", headerName: "Objekt", flex: 4 },
      { field: "category", headerName: "Kategorie", flex: 2 },
      { field: "storageLocation", headerName: "Ort", flex: 2 },
      { field: "description", headerName: "Beschreibung", flex: 2 },
      { field: "marking", headerName: "Beschriftung", flex: 2 }
    );

    if (props.mode === "manageItems")
      columns.push({
        field: "lendFraction",
        headerName: "Verliehen",
        flex: 1,
        renderCell: (params) => (
          <FractionBar
            valueTotal={params.row.count}
            valueFraction={params.row.countLend}
          />
        ),
      });
    return columns;
  }, [props]);

  const rows: GridRowsProp = useMemo(
    () =>
      data && data.items
        ? data.items.map((bItem: BackendItem) => {
            return {
              ...bItem,
              storageLocation: `${bItem.storageLocation.site}${
                bItem.storageLocation.place
                  ? " → " + bItem.storageLocation.place
                  : ""
              }`,
              summary: `${bItem.count === 1 ? "" : bItem.count + "*"} ${
                bItem.label
              }`,
              idBackend: bItem.id,
              lendFraction: bItem.countLend / bItem.count,
              countAvailable: getAvailableItemCount(
                data,
                bItem.count,
                bItem.countLend,
                bItem.id,
                props.lendingId
              ),
            };
          })
        : [],
    [data]
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      autoHeight
      disableSelectionOnClick
      style={{ marginBottom: "1em" }}
      components={{
        Toolbar: GridToolbar,
      }}
      density="compact"
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) =>
        setColumnVisibilityModel(newModel)
      }
      onRowClick={
        props.mode === "manageItems"
          ? (params) => navigate(`/items/${params.row.id}`)
          : undefined
      }
    />
  );
}

const needsToRerender = (
  prevProps: Readonly<ItemTableProps>,
  nextProps: Readonly<ItemTableProps>
) =>
  prevProps?.data?.dataHash === nextProps?.data?.dataHash &&
  prevProps.plannedSelections === nextProps.plannedSelections &&
  prevProps.lendSelections === nextProps.lendSelections &&
  prevProps.lendingId === nextProps.lendingId &&
  prevProps.mode === nextProps.mode;

export default memo(ItemTable, needsToRerender);
